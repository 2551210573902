<template>
  <div class="card card-custom grid-stack-item-content">
    <div class="card-body">
      <h1>
        {{ $t("dashboard.welcomeToTenant") }}
        <span
          v-if="selectedClient && selectedClient.uuid"
          class="text-capitalize"
        >
          , {{ selectedClient.clientName }}
        </span>
        !
      </h1>
      <p>{{ $t("dashboard.tenantShortDescription") }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TenantsWelcomeAppsWelcome",
  computed: {
    ...mapGetters(["selectedClient"])
  }
};
</script>
