<template>
  <div>
    <div class="grid-stack">
      <div
        class="grid-stack-item"
        data-gs-x="0"
        data-gs-y="0"
        data-gs-width="8"
        data-gs-height="2"
      >
        <Welcome></Welcome>
      </div>
      <div
        class="grid-stack-item"
        data-gs-x="0"
        data-gs-y="2"
        data-gs-width="8"
        data-gs-height="4"
      >
        <Sections></Sections>
      </div>
      <div
        v-if="tenantApiKey"
        id="ClientsContactPersons"
        class="grid-stack-item"
        data-gs-x="10"
        data-gs-y="0"
        data-gs-width="2"
        data-gs-height="5"
      >
        <ClientsContactPersons
          :key="tenantApiKey || partnerApiKey"
          :apikey="tenantApiKey"
        />
      </div>
      <div
        v-if="tenantApiKey && tenantApiKey !== partnerApiKey"
        id="ClientsContactPartner"
        class="grid-stack-item"
        data-gs-x="10"
        data-gs-y="5"
        data-gs-width="2"
        data-gs-height="4"
      >
        <ClientsContactPartner :key="tenantApiKey" />
      </div>
    </div>
  </div>
</template>

<script>
import { GridStack } from "gridstack";
import "gridstack/dist/gridstack.css";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Welcome from "@/components/Tenants/Welcome/Apps/Welcome";
import Sections from "@/components/Tenants/Welcome/Apps/Sections";
import ClientsContactPersons from "@/components/General/Dashboard/Apps/Clients/ContactPerson";
import ClientsContactPartner from "@/components/General/Dashboard/Apps/Clients/Partner";

export default {
  name: "TenantsWelcomeIndex",
  components: {
    Welcome,
    Sections,
    ClientsContactPersons,
    ClientsContactPartner
  },
  data() {
    return {
      gridStack: null,
      userType: null,
      tenantApiKey: null,
      partnerApiKey: null
    };
  },
  mounted() {
    this.$forceUpdate();
    this.loadGridStack();

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.dashboard"),
        route: "/tenant/welcome"
      }
    ]);
  },
  created() {
    this.partnerApiKey = this.$store.getters.apiTokenPartner;
    this.tenantApiKey = this.$store.getters.apiToken;
    this.userType = this.$store.getters.userType;
  },
  beforeDestroy() {
    this.removeGridStack();
  },
  methods: {
    loadGridStack() {
      const options = {
        cellHeight: 80,
        margin: 10
      };

      this.gridStack = GridStack.init(options);
    },
    removeGridStack() {
      if (!this.gridStack) return;
      this.gridStack.destroy(true);
    }
  }
};
</script>
